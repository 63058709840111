import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import store from "store";
// mui
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
// myComponents
import SoftBox from "myComponents/SoftBox";
// ui context
import { useSoftUIController } from "context";
// utils
import { getAllowedRoutes } from "utils/getAllowedRoutes";
// routes congig
import routesConfig from "config/routesConfig";
// anime
import JSONAnimation from "animations";
// selectors
import { selectBranchesMapByBranchCode } from "redux/organization/branches/branches.selectors";
// hooks
import useErrorAlert from "hooks/useErrorAlert";
import useAsyncEvent from "layout/primeLayout/useAsyncEvents";
// actions
import { resetBackdropProcess, resetAlertInfo } from "redux/app/app.slice";
// selectors
import {
  selectAlertInfo,
  selectIsAppInitialized,
  selectIsProcessing,
  selectProcessStatus,
  selectProcessType,
  selectLayoutType,
  selectLoginUser,
} from "redux/app/app.selectors";
import {
  selectPrivilegesOfRoles,
  selectAttendanceConfig,
} from "redux/settings/settings.selector";
// SUB
import { subscribe } from "helpers/pubsub/utils";
import MakeApiCall from "helpers/pubsub/events/makeApiCall";
// components
import Topbar from "./Topbar";
import Sidenav from "./Sidenav";
import SettingsBar from "./SettingsBar";
import Feedback from "../../components/Feedback";
import logoMap from "./logoURLMap";
import Offline from "./Offline";
import { Main } from "./styles";

const PrimeLayout = () => {
  const isMobile = useMediaQuery("(max-width:992px)");
  const dispatch = useDispatch();
  const [controller] = useSoftUIController();
  const { sidenavColor, miniSidenav } = controller;
  const [offLine, setIfOffLine] = useState(false);
  const [callHandler] = useAsyncEvent();
  const branchesMap = useSelector(selectBranchesMapByBranchCode);
  // selectors
  const isProcessing = useSelector(selectIsProcessing);
  const processStatus = useSelector(selectProcessStatus);
  const isAppInitialized = useSelector(selectIsAppInitialized);
  const processType = useSelector(selectProcessType);
  const alertInfo = useSelector(selectAlertInfo);
  const layoutType = useSelector(selectLayoutType);
  const privileges = useSelector(selectPrivilegesOfRoles);
  const attendanceConf = useSelector(selectAttendanceConfig);
  const {
    assignedRoles,
    addedPrivileges,
    removedPrivilegs,
    // employeeId = "",
    _id = "",
  } = store.get("user") || {};
  const loginUser = useSelector(selectLoginUser);
  const { userId } = loginUser || { userId: "" };

  const [mess, result, open, setOpen] = useErrorAlert(
    processStatus,
    resetBackdropProcess
  );

  const { alertOpen, msg } = alertInfo;

  const noDefaultLayout = ["timetable/create", "manage-roles&privileges"];

  const mainLayout = (() => {
    const t1 = layoutType === "main";
    const t3 = noDefaultLayout.every(
      (path) => !window.location.pathname.includes(path)
    );
    return t1 && t3;
  })();

  const instituteId = store.get("instituteId");

  const handleAlertClose = () => {
    setOpen(false);
    dispatch(resetBackdropProcess());
  };

  const handleAppAlertClose = () => {
    dispatch(resetAlertInfo());
  };

  useEffect(() => {
    console.log("subscribe call back fired");
    const handle = subscribe(MakeApiCall, (args) => {
      console.log("callback fired...");
      callHandler(args);
    });

    return function cleanup() {
      handle.unsubscribe();
    };
  });

  useEffect(() => {
    setIfOffLine(!window.navigator.onLine);
  }, [window.navigator.onLine]);

  useEffect(() => {
    if (
      navigator.userAgent.includes("Android") &&
      window.Android &&
      userId &&
      window.Android.postFCMToken
    ) {
      window.Android?.postFCMToken(_id);
    }
  }, [userId]);

  const routes = useMemo(
    () =>
      getAllowedRoutes(
        routesConfig,
        assignedRoles,
        privileges,
        attendanceConf,
        store.get("instituteId"),
        addedPrivileges,
        removedPrivilegs
      )
        .map((item) => {
          if (
            getAllowedRoutes(
              item.children,
              assignedRoles,
              privileges,
              attendanceConf,
              store.get("instituteId"),
              addedPrivileges,
              removedPrivilegs
            )?.filter((it) => !it?.noUiLink).length === 0
          ) {
            return null;
          }
          return {
            type: "collapse",
            name: item.title,
            key: item.path,
            route: item.path,
            icon: item.icon,
            component: item.component,
            noCollapse: false,
            children: getAllowedRoutes(
              item.children,
              assignedRoles,
              privileges,
              attendanceConf,
              store.get("instituteId"),
              addedPrivileges,
              removedPrivilegs
            ),
          };
        })
        .filter((item) => item),
    [addedPrivileges, removedPrivilegs, privileges]
  );

  // useEffect(() => {
  //   const new_applicatiion = !!routes
  //     ?.find((item) => item.route === "admissions")
  //     ?.children?.find((item) => item.path === "application");
  //   const payload = { app_ranges: new_applicatiion };
  //   dispatch({ type: "FETCH_METADATA", payload });
  // }, []);

  if (!isAppInitialized && offLine) return <Offline fullScreen />;

  if (!isAppInitialized)
    return (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <JSONAnimation type="loading" />
      </Box>
    );

  const getValues = () => {
    if (store.get("instituteId") === "65772522ee64cfa8dc9851f2") {
      if (Object.values(branchesMap)?.length > 1) {
        return logoMap[instituteId]?.url;
      }
      if (Object.values(branchesMap)?.[0]?.branchName?.includes("Prime")) {
        return logoMap[`${instituteId}_prime`].url;
      }
      if (Object.values(branchesMap)?.[0]?.branchName?.includes("Digi")) {
        return logoMap[`${instituteId}_digi`].url;
      }
    }
    return logoMap[instituteId]?.url;
  };
  return (
    <>
      {mainLayout ? (
        <>
          <Topbar light />
          <Sidenav
            color={sidenavColor}
            brand={getValues()}
            InsTitle={logoMap[instituteId]?.title}
            routes={routes}
            branchId={logoMap[instituteId]?.branchId}
            subTitle={logoMap[instituteId]?.subTitle}
          />
          <Main open={miniSidenav}>
            <SoftBox height={isMobile ? "70px" : "40px"} />
            {offLine ? <Offline /> : <Outlet />}
          </Main>
        </>
      ) : offLine ? (
        <Offline />
      ) : (
        <Outlet />
      )}

      <SettingsBar />
      <Backdrop
        sx={{ color: "#fff", zIndex: 1301 }}
        open={isProcessing && processType !== "noBackdrop"}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {mess && result ? (
        <Feedback
          msg={mess}
          open={open}
          handleClose={handleAlertClose}
          type={result}
        />
      ) : null}

      <Feedback
        msg={msg}
        open={alertOpen}
        handleClose={handleAppAlertClose}
        type="info"
        aletStyles={{
          backgroundColor: "#808080	",
          color: "#ffff",
        }}
        position={{ x: "top", y: "center" }}
      />
    </>
  );
};

export default PrimeLayout;
