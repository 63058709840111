import { useLocation, Link } from "react-router-dom";
// materialui
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
// my components
import SoftButton from "myComponents/SoftButton";
import SoftBox from "myComponents/SoftBox";
// hooks
import useMyPrivileges from "hooks/useMyPrivileges";

const AcademicSetting = () => {
  const location = useLocation();
  const access = useMyPrivileges([
    "academic_attendance_timings_read",
    "academic_attendance_timings_write",
    "lock_uploaded_data_files",
    "disable_results",
  ]);
  const settings = [];
  if (
    access.academic_attendance_timings_read ||
    access.academic_attendance_timings_write
  ) {
    settings.push({
      path: "attendance-timings",
      name: "Attendance Timings",
    });
  }
  if (access.lock_uploaded_data_files || access.disable_results) {
    settings.push({
      path: "exam-settings",
      name: "Exam Settings",
    });
  }

  return (
    <SoftBox mt={2}>
      <Grid container spacing={2}>
        {settings.map((set, index) => (
          <Grid item xs={12}>
            <Link key={index} to={set.path} state={{ path: location.pathname }}>
              <SoftButton color="info">
                {set.name}&nbsp;
                <Icon>
                  <KeyboardArrowRightRoundedIcon />
                </Icon>
              </SoftButton>
            </Link>
          </Grid>
        ))}
      </Grid>
    </SoftBox>
  );
};

export default AcademicSetting;
