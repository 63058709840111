import flatten from "lodash/flatten";
// import store from "store";
// icon
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
// Helpers
import lazy from "helpers/LazyImport";
// features
import features from "config/features";
// body layout
import Container from "../../layout/simpleContainer";
import TcGeneration from "../../views/Academics/Reports/TcGeneration";
import DocumnetsSumbitted from "../../views/Academics/Reports/DocumentsSudmitted";
import InOutMcbReport from "../../views/Academics/Reports/InOutMcbReport";
// const TimeTable = lazy(() =>
//   import("../../views/Academics/Scheduler/TimeTableCreation")
// );

const Reports = lazy(() => import("../../views/Academics/Reports"));
const ManageTeachers = lazy(() =>
  import("../../views/Academics/TimeTable/ManageTeacher")
);
const Assignments = lazy(() => import("../../views/Academics/Assignments"));
const TimeTableIndex = lazy(() => import("../../views/Academics/TimeTable"));
const TimeTableGeneration = lazy(() =>
  import("../../views/Academics/TimeTable/TimetableGenertion")
);
const HallTicketEntry = lazy(() =>
  import("../../views/Academics/HallTicketEntry")
);

export default {
  component: <Container />,
  title: "Academics",
  basePath: "app",
  path: "academics",
  features: flatten(Object.values(features.academics)),
  icon: <SchoolRoundedIcon />,
  // authorizedRoles: ["academics", roles.organization.SUPER_ADMIN],
  children: [
    {
      component: <Assignments />,
      title: "Assignments",
      features: features.academics.assignments,
      basePath: "academics",
      path: "assignments",
      authorizedRoles: [],
    },
    {
      component: <HallTicketEntry />,
      title: "Hall Ticket Entry",
      features: features.academics.hallTicketEntry,
      basePath: "academics",
      path: "hallTicketEntry",
      authorizedRoles: [],
    },
    {
      component: <Container />,
      index: <Reports />,
      title: "Reports",
      features: features.academics.reports,
      basePath: "academics",
      path: "reports",
      children: [
        {
          component: <TcGeneration />,
          title: "Tc Generation Report",
          basePath: "reports",
          path: "tc-generation-report",
          features: ["tc_generation_report"],
        },
        {
          component: <DocumnetsSumbitted />,
          title: "Documnets Sumbitted",
          basePath: "reports",
          path: "documents-submitted-report",
          features: ["documents_submitted_report"],
        },
        {
          component: <InOutMcbReport />,
          title: "In-Out-Report",
          basePath: "reports",
          path: "in-out-report",
          features: ["in_out_Report"],
        },
      ],
    },
    {
      component: <Container />,
      title: "Time Table",
      features: features.academics.scheduler,
      basePath: "academics",
      path: "timetable",
      authorizedRoles: [],
      index: <TimeTableIndex />,
      children: [
        {
          component: <TimeTableGeneration />,
          title: "Time Table",
          features: ["scheduler_create"],
          basePath: "scheduler",
          path: "create",
          authorizedRoles: [],
        },
        {
          component: <ManageTeachers />,
          title: "Manage Teachers and sections",
          features: ["Manage_teachers_and_sections"],
          basePath: "scheduler",
          path: "manage-t&c",
          authorizedRoles: [],
        },
      ],
    },
  ],
};
