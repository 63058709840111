import { useState } from "react";
// material ui
import EditIcon from "@mui/icons-material/Edit";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import { Tooltip } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Checkbox from "@mui/material/Checkbox";
import isCurrentDate from "utils/isCurrentDate";
// hook
import useMyPrivileges from "hooks/useMyPrivileges";
// x-data-grid
import { GridRowModes, GridActionsCellItem } from "@mui/x-data-grid-pro";

// myCompoents
import SoftInput from "myComponents/SoftInput";
// styled
import StyledDataGrid from "./styles";
import CustomToolbar from "./ToolBar";
import GridCheckBox from "../../components/CheckBoxType";

const hearderGroupStyles = (color, bgColor) => ({
  backgroundColor: bgColor || ((theme) => theme.palette.info.main),
  color: color || "#fff",
  "& .MuiDataGrid-columnHeaderDraggableContainer": {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "center",
    },
  },
});

export default function SoftDataGrid({
  rows,
  columns,
  processRowUpdate,
  onProcessRowUpdateError,
  actions,
  handleDeleteClick,
  handleEditClick,
  handleViewClick,
  handleAdmissionFeePayClick,
  handlePrintClick,
  toolBar = false,
  AdditionalComponent,
  CheckBoxcomponent,
  checkboxType = false,
  exportForTable = true,
  height,
  FooterComponent,
  cellBorder,
  footerProps,
  containerStyles,
  latestDataToDisplay = true,
  haveColumnGrouping,
  elevation = true,
  gridPagination = true,
  columnsForTable = true,
  title = null,
  sensitiveInfo = false,
  params,
  getTreeDataPath,
  treeData,
  density,
  columnGroupingModel,
  ...rest
}) {
  const [rowModesModel, setRowModesModel] = useState({});
  const access = useMyPrivileges(["cancellation_txn"]);
  const additonalComponents = {};
  additonalComponents.LoadingOverlay = LinearProgress;
  if (checkboxType)
    additonalComponents.BaseCheckbox = GridCheckBox(checkboxType);
  if (FooterComponent) additonalComponents.Footer = FooterComponent;
  if (toolBar && AdditionalComponent)
    additonalComponents.Toolbar = CustomToolbar({
      Component: AdditionalComponent,
      gridPagination,
      columnsForTable,
      exportForTable,
      title,
      sensitiveInfo,
      dataParams: params || {},
    });
  // if (!havePagenation) additonalComponents.Pagination = emptyFooter;
  let finalRows;
  if (latestDataToDisplay === true) {
    finalRows = [...rows].reverse();
  } else {
    finalRows = rows;
  }
  const handleRowEditStartOrStop = (_, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleDefaultEditClick = (id) => () =>
    handleEditClick
      ? handleEditClick(id)
      : setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.Edit },
        });
  const handleCloneClick = (id) => () =>
    handleEditClick
      ? handleEditClick(id)
      : setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.Edit },
        });

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const getActions = (actionsObj) => {
    if (
      actionsObj &&
      (actionsObj.edit || actionsObj.del || actionsObj.view || actionsObj.print)
    ) {
      const col = {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 130,
        flex: 2,
        cellClassName: "actions",
        align: "left",
        headerAlign: "left",
      };
      col.getActions = ({ id, row }) => {
        // Check if the dates are equal
        const CurrentDate = access?.cancellation_txn
          ? true
          : isCurrentDate(row.receiptDate || row.date);
        console.log(CurrentDate);
        if (row.isPinned) {
          // Return an empty array if the row is pinned, thus no actions will be shown
          return [];
        }
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        const myArr = [];
        if (isInEditMode) {
          myArr.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              // color="inherit"
            />
          );
        } else {
          if (actions.feePay)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Proceed To Pay">
                    <CurrencyRupeeIcon color="dark" />
                  </Tooltip>
                }
                label="Fee pay"
                onClick={() => {
                  handleAdmissionFeePayClick(id);
                }}
              />
            );
          if (actions.edit && row?.status !== "Cancelled")
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Edit">
                    <EditIcon color="info" />
                  </Tooltip>
                }
                label="Edit"
                className="textPrimary"
                onClick={handleDefaultEditClick(id)}
                // color="inherit"
              />
            );

          if (actions.view)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="View">
                    <VisibilityIcon
                      sx={{ fill: (theme) => theme.palette.info.main }}
                    />
                  </Tooltip>
                }
                label="View"
                className="textPrimary"
                onClick={() => handleViewClick(id)}
              />
            );

          if (actions.del && row?.status !== "Cancelled" && CurrentDate)
            myArr.push(
              <GridActionsCellItem
                icon={<DeleteIcon color="error" />}
                label="Delete"
                onClick={() => {
                  handleDeleteClick(id);
                }}
              />
            );
          if (actions.print)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Print">
                    <LocalPrintshopIcon
                      sx={{ fill: (theme) => theme.palette.info.main }}
                    />
                  </Tooltip>
                }
                label="Edit"
                className="textPrimary"
                onClick={() => handlePrintClick(id)}
              />
            );
          if (actions.clone)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Clone">
                    <FileCopyIcon color="info" />
                  </Tooltip>
                }
                label="Clone"
                className="textPrimary"
                onClick={handleCloneClick(id)}
                // color="inherit"
              />
            );
        }

        return myArr;
      };
      return [col];
    }
    return [];
  };

  const allColumns = [...columns].concat(getActions(actions));

  const columnGroupingStyles = haveColumnGrouping
    ? {
        // mt: "1rem",
        "& .column-group-item1": hearderGroupStyles(),
        "& .column-group-item2": hearderGroupStyles(null, "#39D0B8"),
      }
    : {};

  return (
    <TableContainer
      sx={{
        ...containerStyles,
        ...columnGroupingStyles,
        boxShadow: elevation ? null : "none",
        borderRadius: 8,
      }}
    >
      <StyledDataGrid
        rows={finalRows}
        columns={allColumns}
        rowModesModel={rowModesModel}
        // onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStartOrStop}
        onRowEditStop={handleRowEditStartOrStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{
          columnGrouping: true,
          newEditingApi: true,
          lazyLoading: true,
        }}
        checkboxSelection={!!checkboxType}
        components={{
          ...additonalComponents,
          BaseTextField: SoftInput,
          BaseSwitch: Checkbox,
        }}
        // slotProps={{ footer: footerProps }}
        // disableSelectionOnClick
        // disableColumnMenu
        // density={density || "compact"}R
        // pageSize={havePagenation ? pageSize : null}
        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // rowsPerPageOptions={[30, 50, 100]}
        // hideFooter={!havePagenation && !FooterComponent}
        treeData={treeData}
        // disableColumnFilter={!disableChildrenFiltering}
        getTreeDataPath={getTreeDataPath}
        // defaultGroupingExpansionDepth={0}
        // pageSizeOptions={[5]}
        density={density || "compact"}
        pagination
        cellBorder={cellBorder}
        disableRowSelectionOnClick
        columnGroupingModel={columnGroupingModel}
        // autoPageSize
        // editMode="row"
        // paginationModel={paginationModel}
        // onPaginationModelChange={setPaginationModel}
        getRowClassName={(paras) =>
          paras.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        {...rest}
      />
    </TableContainer>
  );
}
