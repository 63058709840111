import flatten from "lodash/flatten";
import store from "store";
// icon
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
// Helpers
import lazyWithRetry from "helpers/LazyImport";
// body layout
import Container from "../../layout/simpleContainer";
// features
import features from "../features";
// modules
// const ApplicationLogs = lazyWithRetry(() => import("../../views/Organization/Logs"));
const FinanceDashboard = lazyWithRetry(() =>
  import(
    "../../views/Organization/ChairmanDashBoard/FeeCollection/DescDashboard"
  )
);
const ExpenseDashboard = lazyWithRetry(() =>
  import("../../views/Organization/ChairmanDashBoard/Expenses/DescDashboard")
);
const FinanceDetials = lazyWithRetry(() =>
  import(
    "../../views/Organization/ChairmanDashBoard/FeeCollection/FeeTypesDetials"
  )
);
const AdmissionsDashboard = lazyWithRetry(() =>
  import(
    "../../views/Organization/ChairmanDashBoard/AdmissionsDashboard/overAllAdmissionsDashborad"
  )
);
const AdmissionsOverAllTables = lazyWithRetry(() =>
  import(
    "../../views/Organization/ChairmanDashBoard/AdmissionsDashboard/AdmissionsOverAllTable"
  )
);

const AttendanceDashboard = lazyWithRetry(() =>
  import(
    "../../views/Organization/ChairmanDashBoard/AttendanceDashboard/OverAllAttendanceDashboard"
  )
);

const CommunicationDashboard = lazyWithRetry(() =>
  import(
    "../../views/Organization/ChairmanDashBoard/CommunicationDashboard/OverAllCommunicationDashboard"
  )
);

export default {
  component: <Container />,
  title: "App",
  basePath: "app",
  noUiLink: true,
  // path: "dashboard",
  icon: <CorporateFareRoundedIcon />,
  features: flatten(Object.values(features.organization)),
  children: [
    // {
    //   component: <SetUpInstitute />,
    //   title: "SetUp Institute",
    //   basePath: "organization",
    //   noUiLink: true,
    //   noUiLink: store.get("instituteId") !== "5d679d49c136660a09596d85",
    //   path: "institute-setup",
    //   features: features.organization,
    // },
    {
      component: <FinanceDashboard />,
      title: "Finance Dashboard",
      basePath: "app",
      noUiLink: true,
      path: "finance-dashboard",
      features: features.organization.Finance_tab,
    },
    {
      component: <ExpenseDashboard />,
      title: "Expenses Dashboard",
      basePath: "app",
      noUiLink: true,
      path: "expenses-dashboard",
      features: features.organization.expenses_tab,
    },

    {
      component: <FinanceDetials />,
      title: "Fee Details",
      basePath: "finance-dashboard",
      path: "details",
      features: features.organization.Finance_tab,
      noUiLink: true,
    },
    {
      component: <AdmissionsDashboard />,
      title: "Admissions Dashboard",
      basePath: "app",
      noUiLink: true,
      path: "admissions-dashboard",
      features: features.organization.admission_tab,
    },
    {
      component: <AdmissionsOverAllTables />,
      title: "Admissions Dashboard",
      basePath: "app",
      noUiLink: true,
      path: "admissions-AcademicYear-dashboard",
      features: features.organization.admission_tab,
    },
    {
      component: <AttendanceDashboard />,
      title: "Attendance Dashboard",
      basePath: "app",
      noUiLink: true,
      path: "attendance-dashboard",
      features: features.organization.attendance_tab,
    },
    {
      component: <CommunicationDashboard />,
      title: "Communication Dashboard",
      basePath: "app",
      noUiLink: true,
      path: "communication-dashboard",
      // features: features.organization.attendance_tab,
    },
  ],
};
