import { createSelector } from "@reduxjs/toolkit";

const selectBatches = (state) => state.batches;

export const selectBatchesData = createSelector(
  [selectBatches],
  (batches) => batches.batchesData
);

export const selectInstituteBatches = createSelector(
  [selectBatches],
  (batches) => batches.instituteBatches
);

export const selectBatchesMap = createSelector(
  [selectInstituteBatches],
  (batchesArr) =>
    batchesArr.reduce((a, c) => {
      a[c.batchId] = c;
      return a;
    }, {})
);

export const selectBatchFromMapByBatchId = (id) =>
  createSelector([selectBatchesMap], (map) => map[id]);

export const instituteHaveBatches = createSelector(
  [selectBatches],
  (batches) => batches.instituteBatches && batches.instituteBatches?.length > 0
);
