function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } =
    theme;
  const { active } = ownerState;

  const { dark, white, text, transparent } = palette;
  const { xxl } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    background: active ? white.main : transparent.main,
    color: active ? dark.main : text.main,
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(6)}`,
    // margin: `0 ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: active ? xxl : "none",
    [breakpoints.up("xl")]: {
      transition: transitions.create("box-shadow", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } =
    theme;
  const { active, color } = ownerState;

  const { white, info, light, gradients } = palette;
  const { md } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    background: () => {
      if (active) {
        return color === "default" ? info.main : palette[color].main;
      }

      return light.main;
    },
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    boxShadow: md,
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up("xl")]: {
      background: () => {
        let background;

        if (!active) {
          background = light.main;
        } else if (color === "default") {
          background = info.main;
        } else if (color === "warning") {
          background = gradients.warning.main;
        } else {
          background = palette[color].main;
        }

        return background;
      },
    },

    "& svg, svg g": {
      fill: active ? white.main : gradients.dark.state,
    },
  };
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: active ? white.main : gradients.dark.state,
});

function collapseArrow(theme, ownerState) {
  const { functions } = theme;
  const { open } = ownerState;

  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(12.8),
    transition: "transform 300ms",
    transform: open ? "rotate(-180deg)" : null,
  };
}

function collapseText(theme, ownerState) {
  const { typography, functions } = theme;
  const { active } = ownerState;

  const { size, fontWeightMedium, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(12.8),
    "& span": {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

function childText(theme, ownerState) {
  const { typography, functions, palette } = theme;
  const { open, active } = ownerState;

  const { size, fontWeightMedium, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  const props = open
    ? {
        color: palette?.dark.main,
        backgroundColor: palette?.dark.main,
        width: "0.5rem",
        height: "0.5rem",
      }
    : {
        backgroundColor: palette?.gradients?.dark.state,
        color: palette?.gradients?.dark.state,
        width: "0.3125rem",
        height: "0.3125rem",
      };

  return {
    width: "100%",
    position: "relative",
    marginLeft: pxToRem(62),
    marginRight: pxToRem(12.8),
    fontSize: size.sm,
    transition: "transform 300ms",
    padding: size.sm,
    fontWeight: active ? fontWeightMedium : fontWeightRegular,
    opacity: open ? 1 : 0.6,

    "&::before": {
      content: '""',
      fontWeight: "400",
      display: "flex",
      WebkitBoxAlign: "center",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "-1.125rem",
      borderRadius: "50%",
      fontSize: "0.875rem",
      ...props,
    },

    "& span": {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.sm,
    },
  };
}

export {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
  childText,
};
