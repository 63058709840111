const institutesUrlMap = {
  "5fa17e1e5eff2c02d9eb32c1": "trinity.onesaz.com",
  "5e7370083d8520a40c4ceaaa": "bhashyam.onesaz.com",
  "5e9098aaa218252d49be5a3b": "sreducation.onesaz.com",
  "5ee85fc60f25a93146ea0bf9": "sriabhidakp.onesaz.com",
  "5ea3572fe301133d0a73e9e0": "miity.onesaz.com",
  "5d4c80481a4a8320de0b9e8b": "demo.onesaz.com",
  "5dc1388b22bdc209de6a4d80": "viitjee.onesaz.com",
  "5e9b3d6cdbae145f55d1e712": "vinners.onesaz.com",
  "5ee85fc60f25a93146ea0bg9": "sriabhida.onesaz.com",
  "5fd25b12ed14200ff702f738": "tirumala.onesaz.com",
  "602f25174f379c03fa0f7737": "medicon.onesaz.com",
  "608ea4eea4105bb4fbc94822": "impulse.onesaz.com",
  "61db4c3da28e11700a6064ac": "sandeepani.onesaz.com",
  "61e6489a9b609f2143aab796": "aiat.onesaz.com",
  "5e521e0da587c5bcefdb7c90": "valleyoak.onesaz.com",
  "62b317ab4406d92c11fd4257": "scjc.onesaz.com",
  "5dd2de6fc539670ff56e37fc": "pjcmncl.onesaz.com",
  "61e850055876f313e30ea1de": "agastya.onesaz.com",
  "63a943927e91069155cc07da": "sanskriti.onesaz.com",
  "6422b982a32e9b7c5498b129": "ligadepatil.onesaz.com",
  "6617918287bfb9f18b6d32a5": "ybrant.onesaz.com",
  "65772522ee64cfa8dc9851f2": "sr.onesaz.com",
  "6655751f14c7f8079c692091": "srexpenses.onesaz.com",
  "5ee85fc60f25a93146ea0bg1": "pinegrove.onesaz.com",
  "5ee85fc60f25a93146ea0n4o": "neutrinoacademy.onesaz.com",
  "5ee85fc60f25a93146ea0a4h": "vijna.onesaz.com",
  "5ee85fc60f25a93146ea0ayt": "ayati.onesaz.com",
  "646634c8a400ecffc1306d0d": "turito.onesaz.com",
  "5ee85fc60f25a93146ea0s01n": "sundar.onesaz.com",
  "5ee85fc60f25a93146ea0coz": "cognizant.onesaz.com",
  "5ee85fc60f25a93146ea0meta": "metamind.onesaz.com",
  "66c430d428cbcc7c97d22a27": "motion.onesaz.com",
  "66d6f203d1e9e56a0955f6b5": "kle.onesaz.com",
  "66d7fd5ed38d3afac6db0696": "excellencia.onesaz.com",
  "66e2a9114c68e81e10ee021c": "arastu.onesaz.com",
};

const institutesMap = {
  "bhashyamadmin.onesaz.com": "5e7370083d8520a40c4ceaaa",
  "srgroup.onesaz.com": "65772522ee64cfa8dc9851f2",
  "srexpenses.onesaz.com": "6655751f14c7f8079c692091",
  "sradmin.onesaz.com": "5e9098aaa218252d49be5a3b",
  "sriabhidaadmin.onesaz.com": "5ee85fc60f25a93146ea0bf9",
  "sriabhidaattapuradmin.onesaz.com": "5ee85fc60f25a93146ea0bg9",
  "miityadmin.onesaz.com": "5ea3572fe301133d0a73e9e0",
  "viitjeeadmin.onesaz.com": "5dc1388b22bdc209de6a4d80",
  "vinnersadmin.onesaz.com": "5e9b3d6cdbae145f55d1e712",
  "vosadmin.onesaz.com": "645b4332b98a5dc4ce54256d",
  "admin.onesaz.com": "5d679d49c136660a09596d85",
  "tirumalaadmin.onesaz.com": "5fd25b12ed14200ff702f738",
  "mediconadmin.onesaz.com": "602f25174f379c03fa0f7737",
  "impulseadmin.onesaz.com": "608ea4eea4105bb4fbc94822",
  "sandeepaniadmin.onesaz.com": "61db4c3da28e11700a6064ac",
  "aiatadmin.onesaz.com": "61e6489a9b609f2143aab796",
  "valleyoakadmin.onesaz.com": "5e521e0da587c5bcefdb7c90",
  "scjcadmin.onesaz.com": "62b317ab4406d92c11fd4257",
  "pjcmncladmin.onesaz.com": "5dd2de6fc539670ff56e37fc",
  "agastyaadmin.onesaz.com": "61e850055876f313e30ea1de",
  "edifyadmin.onesaz.com": "63a943927e91069155cc07da",
  "lpjcadmin.onesaz.com": "6422b982a32e9b7c5498b129",
  "turitoadmin.onesaz.com": "646634c8a400ecffc1306d0d",
  localhost: "5d679d49c136660a09596d85",
  "ybrantadmin.onesaz.com": "6617918287bfb9f18b6d32a5",
  "pinegroveadmin.onesaz.com": "5ee85fc60f25a93146ea0bg1",
  "sroldadmin.onesaz.com": "5e9098aaa218252d49be5a3b",
  "neutrinoadmin.onesaz.com": "5ee85fc60f25a93146ea0n4o",
  "vijnaadmin.onesaz.com": "5ee85fc60f25a93146ea0a4h",
  "ayatiadmin.onesaz.com": "5ee85fc60f25a93146ea0ayt",
  "sundaradmin.onesaz.com": "5ee85fc60f25a93146ea0s01n",
  "cognizantadmin.onesaz.com": "5ee85fc60f25a93146ea0coz",
  "metamindadmin.onesaz.com": "5ee85fc60f25a93146ea0meta",
  "motionadmin.onesaz.com": "66c430d428cbcc7c97d22a27",
  "kleadmin.onesaz.com": "66d6f203d1e9e56a0955f6b5",
  "excellenciaadmin.onesaz.com": "66d7fd5ed38d3afac6db0696",
  "arastuadmin.onesaz.com": "66e2a9114c68e81e10ee021c",
};

export default institutesUrlMap;

export { institutesMap };
