import { useState } from "react";
import store from "store";
// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
// context
import { useSoftUIController, setMiniSidenav } from "context";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
// selectors
import { useSelector } from "react-redux";

import { selectBranchesMapByBranchCode } from "redux/organization/branches/branches.selectors";
// utils
import generateRandomString from "utils/getRandString";

import { Grid } from "@mui/material";
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";
// import sidenavLogoLabel from "./styles/sidenav";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "./styles/sidenavCollapse";

function Sidenav({
  color,
  brand,
  brandName,
  InsTitle,
  branchId,
  routes,
  subTitle,
  ...rest
}) {
  const isMobile = useMediaQuery("(max-width:992px)");
  const [controller, dispatch] = useSoftUIController();

  const { miniSidenav, transparentSidenav } = controller;
  const branchesMap = useSelector(selectBranchesMapByBranchCode);

  // user?.accessLevel === "branch"
  //       ? BrnchWiseLogos?.[branchId]
  //       : SrcollegeLogo
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[1];
  const [miniOpen, setMiniOpen] = useState(collapseName);

  const closeSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, children }) => {
      let returnValue;
      const active = key === collapseName;
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={`${key}_${generateRandomString(3)}`}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <List key={`${key}_${generateRandomString(3)}`}>
            <ListItem
              component={NavLink}
              to={`/app/${key}`}
              onClick={() => {
                if (key === miniOpen) setMiniOpen("none");
                else setMiniOpen(key);
              }}
            >
              <SoftBox
                {...rest}
                sx={(theme) =>
                  collapseItem(theme, { active, transparentSidenav })
                }
              >
                <ListItemIcon
                  sx={(theme) =>
                    collapseIconBox(theme, {
                      active,
                      transparentSidenav,
                      color,
                    })
                  }
                >
                  {typeof icon === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active })}>
                      {icon}
                    </Icon>
                  ) : (
                    icon
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={(theme) =>
                    collapseText(theme, {
                      miniSidenav,
                      transparentSidenav,
                      active,
                    })
                  }
                />
                <Icon
                  sx={(theme) =>
                    collapseArrow(theme, {
                      miniSidenav,
                      transparentSidenav,
                      open: key === miniOpen,
                    })
                  }
                >
                  keyboard_arrow_down
                </Icon>
              </SoftBox>
            </ListItem>
            <ListItem>
              <SidenavCollapse
                color={color}
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
                open={key === miniOpen}
              >
                {children}
              </SidenavCollapse>
            </ListItem>
          </List>
        );
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={`${key}_${generateRandomString(3)}`}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={`${key}_${generateRandomString(3)}`} />;
      }

      return returnValue;
    }
  );

  const drawerContent = () => (
    <>
      <SoftBox px={4} textAlign="center">
        <SoftBox
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>
              <ArrowBackIosIcon />
            </Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component={NavLink}
          to={
            window.location.hostname.includes("expenses")
              ? "/app/expense-management"
              : "/app"
          }
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {brand && (
            <SoftBox
              component="img"
              key={generateRandomString(10)}
              src={brand}
              alt="UI Logo"
              width={
                store.get("instituteId") === "646634c8a400ecffc1306d0d"
                  ? "6.5rem"
                  : store.get("instituteId") === "65772522ee64cfa8dc9851f2" &&
                    Object.values(branchesMap)?.[0]?.instituteType?.includes(
                      "colleges"
                    )
                  ? "5rem"
                  : brand.includes("SR_DIGI_LOGO1") ||
                    store.get("instituteId") === "6617918287bfb9f18b6d32a5" ||
                    store.get("instituteId") === "66c430d428cbcc7c97d22a27" ||
                    store.get("instituteId") === "66e2a9114c68e81e10ee021c"
                  ? "10rem"
                  : "96px"
              }
              height={
                store.get("instituteId") === "646634c8a400ecffc1306d0d"
                  ? "2.5rem"
                  : store.get("instituteId") === "6617918287bfb9f18b6d32a5"
                  ? "2.7rem"
                  : "96px"
              }
            />
          )}
        </SoftBox>
      </SoftBox>

      {store.get("instituteId") === "65772522ee64cfa8dc9851f2" && (
        <>
          <SoftTypography
            fontWeight="bold"
            variant="h5"
            color="dark"
            // sx={{
            //   fontSize:
            //     Object.values(branchesMap)?.[0]?.branchName.length > 20
            //       ? "20px"
            //       : "16px",
            // }}
          >
            {Object.values(branchesMap)?.[0]?.instituteType?.includes(
              "colleges"
            ) && store.get("user").accessLevel !== "branch"
              ? InsTitle
              : null}
          </SoftTypography>
          {store.get("user").accessLevel === "branch" && (
            <SoftTypography
              fontWeight="bold"
              variant="button"
              // color="dark"
              sx={{
                color: "#E7078D",
                fontSize:
                  Object.values(branchesMap)?.[0]?.branchName?.length <= 25
                    ? "16px"
                    : "12px",
              }}
              align="center"
            >
              {Object.values(branchesMap)?.[0]?.branchName}
            </SoftTypography>
          )}
        </>
      )}
      {store.get("instituteId") === "5ee85fc60f25a93146ea0n4o" &&
        store.get("user").accessLevel !== "branch" && (
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <SoftTypography
                fontWeight="bold"
                variant="button"
                // color="dark"
                sx={{
                  color: "#E7078D",
                  fontSize: "16px",
                }}
                align="center"
              >
                {InsTitle}
              </SoftTypography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              {subTitle && (
                <SoftTypography
                  fontWeight="bold"
                  variant="button"
                  color="success"
                  sx={{
                    // color: "#E7078D",
                    fontSize: "12px",
                  }}
                  align="center"
                >
                  {subTitle}
                </SoftTypography>
              )}
            </Grid>
          </Grid>
        )}

      <Divider />
      <List>{renderRoutes}</List>
    </>
  );

  return (
    <SidenavRoot
      anchor="left"
      open={miniSidenav}
      variant={isMobile ? "temporary" : "persistent"}
    >
      {drawerContent()}
    </SidenavRoot>
  );
}

export default Sidenav;
