import flatten from "lodash/flatten";
// mui
import PermPhoneMsgRoundedIcon from "@mui/icons-material/PermPhoneMsgRounded";
// Helpers
import lazy from "helpers/LazyImport";
// features
import features from "config/features";
// body layout
import BodyWrapper from "../../layout/bodyWrapper";
import Container from "../../layout/simpleContainer";

// modules
const Reports = lazy(() => import("../../views/Communications/Reports"));
const DeliveryReport = lazy(() =>
  import("../../views/Communications/Reports/DeliveryReport")
);
const Notifications = lazy(() =>
  import("../../views/Communications/SendNotifications")
);

const SMSTemplate = lazy(() =>
  import("../../views/Communications/SMSTemplate")
);
const CreateTemplates = lazy(() =>
  import("../../views/Communications/CreateTemplates")
);
const SendResults = lazy(() =>
  import("../../views/Communications/SendResults")
);
const ParentCredentials = lazy(() =>
  import("../../views/Communications/SendParentsCredentials")
);
const AllNotifications = lazy(() =>
  import("../../views/Communications/AllNotifications")
);

// const AppNotifcations = lazy(() =>
//   import("../../views/Communications/AppNotification")
// );

export default {
  component: <BodyWrapper />,
  title: "Communications",
  basePath: "app",
  path: "communications",
  icon: <PermPhoneMsgRoundedIcon />,
  features: flatten(Object.values(features.communications)),
  authorizedRoles: flatten(Object.values(features.communications)),
  children: [
    // {
    //   component: <AppNotifcations />,
    //   title: "App Notification",
    //   basePath: "communications",
    //   path: "app-notifications",
    //   features: features.communications.app_notifications,
    // },
    {
      component: <Notifications />,
      title: "Send Notification",
      basePath: "communications",
      path: "notifications",
      features: features.communications.notifications,
    },
    {
      component: <SMSTemplate />,
      title: "SMS",
      basePath: "communications",
      features: features.communications.sms_templates,
      path: "sms-templates",
    },
    {
      component: <SendResults />,
      title: "Send Results",
      basePath: "communications",
      features: features.communications.send_results,
      path: "send-results",
      icon: <SendResults />,
    },
    {
      component: <ParentCredentials />,
      title: "Parent's Credentials",
      basePath: "communications",
      features: features.communications.parent_credentials,
      path: "parent-credentials",
    },
    {
      component: <AllNotifications />,
      title: "View Notifications",
      basePath: "communications",
      features: features.communications.notifications,
      path: "messages",
    },
    {
      component: <CreateTemplates />,
      title: "Templates",
      basePath: "communications",
      features: features.communications.templates,
      path: "templates",
    },
    {
      component: <Container />,
      index: <Reports />,
      title: "Reports",
      features: features.communications.reports,
      basePath: "communications",
      path: "reports",
      children: [
        {
          component: <DeliveryReport />,
          title: "SMS Delivery Report",
          basePath: "reports",
          path: "sms-delivery-report",
          features: ["sms-delivery_report"],
        },
      ],
    },
  ],
};
