import { createSelector } from "@reduxjs/toolkit";

export const selectSettings = (state) => state.settings;

export const selectInstituteConfig = createSelector(
  [selectSettings],
  (s) => s.instituteConfig || {}
);

export const selectConfigId = createSelector(
  [selectSettings],
  (s) => s.configId
);
export const selectAyBtInfo = createSelector(
  [selectSettings],
  (s) => s.AyInfo || {}
);

export const selectFieldsFromInstituteConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.admissionFormFields
);
export const selectFieldsAppFromInstituteConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.applicationFormFields
);
export const selectFieldsEnqFromInstituteConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.enquiryFormFields
);

export const selectStaffFieldsFromInstituteConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.staffFormFields
);
export const selectApplicationFieldsFromInstituteConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.applicationFormFields || i.admissionFormFields
);

export const selectFromInstituteConfig = (prop) =>
  createSelector([selectInstituteConfig], (i) => i[prop]);

export const selectInstituteRoles = createSelector(
  [selectInstituteConfig],
  (i) => i.roles
);

export const selectPrivilegesOfRoles = createSelector(
  [selectInstituteConfig],
  (i) => i.privileges
);
export const selectAttendanceConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.attendanceConf
);
export const selectDashboardConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.dashboardConfig
);
export const selectParentAccessToEnterAmountConfig = createSelector(
  [selectInstituteConfig],
  (i) => i.parentAccessToEnterAmount
);
export const selectHighAuthorityLockFeeAccess = createSelector(
  [selectInstituteConfig],
  (i) => i.haConcession
);
export const selectAllowedAcademicYearAccess = createSelector(
  [selectInstituteConfig],
  (i) => i.AdmissionsAllowedYears
);
