import { useEffect, useState } from "react";
import store from "store";
// import { enqueueSnackbar } from "notistack";
// mui
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { RadioGroup, Radio } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useField, useFormikContext } from "formik";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
// myComponets
import SoftTextField from "myComponents/SoftTextField";
import SoftAutoComplete from "myComponents/SoftAutoComplete";
import SoftAutoCompleteMultiSelect from "myComponents/SoftAutoComplete/MultiSelect";
import AutocompleteWithVirtual from "myComponents/SoftAutoComplete/virtualize";
import SoftBox from "myComponents/SoftBox";
import Typography from "myComponents/SoftTypography";
// components
import SoftButton from "myComponents/SoftButton";
// styles
import useStyles from "./styles";

export const FieldDropdown = ({
  children,
  form,
  field,
  options,
  small,
  label,
  required,
  optionValuePropName,
  optionLabelPropName,
  simpleOptions,
  readOnly = false,
  helperText,
  virtualList = false,
  getLabelInVirtualList,
  returnNUll,
  firstOptionButton,
  getComponent,
}) => {
  const { name, value } = field;

  const isSRApplication =
    store.get("instituteId") === "65772522ee64cfa8dc9851f2";
  const { setFieldValue } = form;
  const { errors } = useFormikContext();
  if (name === "referral" && returnNUll) return null;
  if (virtualList) {
    return (
      <SoftBox maxWidth="600px" width="100%">
        <AutocompleteWithVirtual
          firstOptionButton={firstOptionButton}
          selected={value || null}
          list={options || []}
          handleSelect={(event, item) => setFieldValue(name, item)}
          label={label}
          filled
          small={small}
          optionLabelPropName={optionLabelPropName}
          optionValuePropName={optionValuePropName}
          className={useStyles().textFieldStyles}
          getLabel={getLabelInVirtualList}
          simpleOptions={simpleOptions ? true : null}
          required={required}
          {...children}
          readOnly={readOnly}
          helperText={helperText}
        />
        <Typography variant="caption" color="error">
          {errors[name] ? errors[name] : null}
        </Typography>
      </SoftBox>
    );
  }
  return (
    <SoftBox maxWidth="600px" width="100%">
      <SoftAutoComplete
        selected={value || null}
        list={options || []}
        handleSelect={(event, item) => setFieldValue(name, item)}
        label={label}
        filled
        small={small}
        className={useStyles().textFieldStyles}
        optionLabelPropName={optionLabelPropName}
        optionValuePropName={optionValuePropName}
        simpleOptions={simpleOptions ? true : null}
        required={required}
        getComponent={getComponent}
        {...children}
        readOnly={readOnly}
        helperText={helperText}
        firstOptionButton={firstOptionButton ? true : null}
        disabled={
          (name === "designation" &&
            options &&
            options.length === 1 &&
            isSRApplication) ||
          name === "applicationStatus"
        }
      />
      <Typography variant="caption" color="error">
        {errors[name] ? errors[name] : null}
      </Typography>
    </SoftBox>
  );
};
export const FieldMultiDropdown = ({
  children,
  form,
  field,
  options,
  label,
  required,
  optionValuePropName,
  optionLabelPropName,
  helperText,
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  const { errors } = useFormikContext();
  return (
    <SoftBox maxWidth="600px" width="100%">
      <SoftAutoCompleteMultiSelect
        selectedValues={value || []}
        values={options || []}
        setValues={(items) => setFieldValue(name, items)}
        label={label}
        required={required}
        helperText={helperText}
        optionLabelPropName={optionLabelPropName}
        optionValuePropName={optionValuePropName}
        textFieldProps={{ required: value?.length === 0 ? required : false }}
        {...children}
      />
      <Typography variant="caption" color="error">
        {errors[name] ? errors[name] : null}
      </Typography>
    </SoftBox>
  );
};
export const InputTextFiled = ({
  children,
  field,
  label,
  small,
  required,
  helperText,
  textType,
  rows,
  multiline = false,
  placeholder,
  readOnly,
  max,
  min,
  noMaxWidth = false,
  disabled,
}) => {
  const { name, value } = field;
  const { errors, setFieldValue } = useFormikContext();
  const [myVal, setMyVal] = useState(value);

  useEffect(() => {
    setMyVal(myVal);
  }, [value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFieldValue(name, myVal);
    }, 400);

    return () => clearTimeout(timeout);
  }, [myVal]);

  function flattenErrors(errors1, parentKey = "") {
    return Object.keys(errors1)?.reduce((acc, key) => {
      const field1 = parentKey ? `${parentKey}.${key}` : key;

      if (typeof errors1[key] === "object" && errors1[key] !== null) {
        return { ...acc, ...flattenErrors(errors1[key], field1) };
      }
      acc[field1] = errors1[key];
      return acc;
    }, {});
  }
  const l = () => {
    if (store.get("instituteId") === "645b4332b98a5dc4ce54256d") {
      if (label === "Full Name") {
        return "Sur Name";
      }

      if (label === "Last Name") {
        return "Student Name";
      }
      return label;
    }
    return label;
  };

  return (
    <SoftBox maxWidth={noMaxWidth ? null : "600px"} width="100%">
      <SoftTextField
        small={small}
        name={name}
        value={myVal}
        required={required}
        type={textType}
        multiline={multiline}
        helperText={helperText}
        rows={rows}
        max={max}
        min={min}
        disabled={disabled}
        placeholder={placeholder}
        InputProps={{ readOnly }}
        onChange={(e) => setMyVal(e.target.value)}
        className={useStyles().textFieldStyles}
        label={l()}
        // InputLabelProps={{
        //   color: "info",
        // }}
        {...children}
      />

      {name === "personalDetails.fatherNumber" && (
        <Typography variant="caption" color="error">
          {errors?.personalDetails?.fatherNumber}
        </Typography>
      )}
      {name === "personalDetails.motherNumber" && (
        <Typography variant="caption" color="error">
          {errors?.personalDetails?.motherNumber}
        </Typography>
      )}
      <Typography variant="caption" color="error">
        {flattenErrors(errors)[name]}
      </Typography>
    </SoftBox>
  );
};

export const TimePickerField = ({ label, required, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const { name, value } = field;
  return (
    <TimePicker
      {...field}
      label={label}
      name={name || ""}
      value={value || null}
      {...props}
      selected={field.value || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
      renderInput={(items) => (
        <TextField
          color="info"
          required
          {...items}
          fullWidth
          size="small"
          className={useStyles().textFieldStyles}
        />
      )}
    />
  );
};

export const FieldDeleteButton = ({ index, remove }) => (
  <Grid item>
    <SoftBox mb={1.5} ml={1} />
    <IconButton
      onClick={() => remove(index)}
      color="error"
      sx={{
        backgroundColor: "#FDEBEF",
      }}
    >
      <DeleteOutlineIcon />
    </IconButton>
  </Grid>
);
export const FieldArrayAddButton = ({
  label,
  handlePush,
  btnProps = { size: "small" },
}) => (
  <Grid
    item
    xs={12}
    container
    display="flex"
    justifyContent="flex-start"
    alignItems="center"
  >
    <SoftButton
      variant="outlined"
      size="small"
      startIcon={<ControlPointRoundedIcon />}
      color="info"
      onClick={handlePush}
      {...btnProps}
    >
      {label}
    </SoftButton>
  </Grid>
);
export const File = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <input
      id="file"
      name={name}
      type="file"
      value={value}
      style={{ color: "blue" }}
      onChange={(event) => {
        setFieldValue("file", event.currentTarget.files[0]);
      }}
      {...children}
    />
  );
};

export const FieldCheckBox = ({
  children,
  field,
  label,
  form,
  heading,
  topSpace = true,
}) => {
  const { name, value } = field;
  // const { handleChange } = form;
  const { values, setFieldValue } = useFormikContext();
  const handleChange = (event) => {
    const newValue = event.target.checked; // Get the checked state of the checkbox
    setFieldValue(name, newValue); // Set the field value to true or false
  };
  return (
    <>
      <SoftBox mb={0.5} ml={0.5}>
        {topSpace && (
          <Typography component="label" variant="caption" fontWeight="bold">
            &nbsp; &nbsp; &nbsp;
          </Typography>
        )}
        {heading && (
          <Typography component="label" variant="body2" fontWeight="bold">
            {heading}
          </Typography>
        )}
      </SoftBox>
      <FormControlLabel
        control={<Checkbox />}
        label={label}
        name={name || ""}
        checked={value || false}
        onChange={handleChange}
        sx={{ marginLeft: 0 }}
        {...children}
      />
    </>
  );
};

export const FieldSwitch = ({ children, field, label }) => {
  const { name } = field;

  const { values, setFieldValue } = useFormikContext();
  return (
    <FormControlLabel
      control={<Switch color="info" />}
      label={label}
      checked={values.name}
      onChange={() => setFieldValue(name, !values.name)}
      {...children}
    />
  );
};
export const FieldRadioButtons = ({ field, options, ...props }) => {
  const { name } = field;
  return (
    <Grid item>
      <RadioGroup row {...field} {...props} name={name}>
        {options?.map((option) => (
          <FormControlLabel
            value={option}
            control={<Radio color="info" />}
            label={option}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};
// export const FieldRadioButtons = ({ field, label1, label2 }) => {
//   const { name } = field;
//   const { values, setFieldValue } = useFormikContext();

//   return (
//     <RadioGroup color="info">
//       <FormControlLabel
//         value={name}
//         control={<Radio color="info" />}
//         label={label1}
//         onChange={() => setFieldValue(name, !values.name)}
//       />
//       <FormControlLabel
//         value={name}
//         control={<Radio color="info" />}
//         label={label2}
//         onChange={() => setFieldValue(name, !values.name)}
//       />
//     </RadioGroup>
//   );
// };
export const FormTag = ({ children, title }) => (
  <Grid item container xs={12}>
    <Typography color="text.dark" padding={1} fontSize={14} fontWeight={600}>
      {title}
    </Typography>
    <Container> {children}</Container>
  </Grid>
);

export const Container = ({ children }) => (
  <Grid
    container
    padding={1}
    direction="row"
    display="flex"
    spacing={{ xs: 1, md: 1 }}
    columns={{ xs: 2, sm: 6, md: 8, lg: 10 }}
  >
    {children}
  </Grid>
);
